


.full-height-width{
	position: relative;
	overflow: hidden;
	width: 100%;
	height: 100%;
	max-width: '350px';
	min-height:  '160px';
}

.anim-container{
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	height: 100%;
	width: 100%;
	padding: 50px 0;
	flex-direction: column;
	justify-content: center;
	align-content:center; 
	align-items: center;
	z-index: 2;
	transition: .3s opacity;
}


